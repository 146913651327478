export const syrups = {
  'Almond Orgeat Syrup': 'https://www.liberandcompany.com/collections/syrups/products/almond-orgeat-syrup',
  'Blood Orange Cordial': 'https://www.liberandcompany.com/collections/syrups/products/blood-orange-cordial',
  'Caramelized Fig Syrup': 'https://www.liberandcompany.com/collections/syrups/products/caramelized-fig-syrup',
  'Chai Spice Tea Syrup':
    'https://www.liberandcompany.com/collections/syrups/products/limited-edition-chai-spice-tea-syrup',
  'Classic Gum Syrup': 'https://www.liberandcompany.com/collections/syrups/products/gum-syrup-1',
  'Demerara Gum Syrup': 'https://www.liberandcompany.com/collections/syrups/products/demerara-gum-syrup',
  'Fiery Ginger Syrup': 'https://www.liberandcompany.com/collections/syrups/products/fiery-ginger-syrup',
  'Lemon Lavender Cordial': 'https://www.liberandcompany.com/collections/syrups/products/lemon-lavender-cordial',
  'Old Fashioned Cocktail Syrup':
    'https://www.liberandcompany.com/collections/syrups/products/old-fashioned-cocktail-syrup',
  'Old Fashioned Syrup':
    'https://www.liberandcompany.com/collections/syrups/products/old-fashioned-cocktail-syrup',
  'Pineapple Gum Syrup': 'https://www.liberandcompany.com/collections/syrups/products/pineapple-gum-syrup',
  'Premium Tonic Syrup': 'https://www.liberandcompany.com/collections/syrups/products/premium-tonic-syrup',
  'Raspberry Gum Syrup': 'https://www.liberandcompany.com/collections/syrups/products/raspberry-gum-syrup',
  'Real Grenadine': 'https://www.liberandcompany.com/collections/syrups/products/grenadine',
  'Rio Red Grapefruit Cordial':
    'https://www.liberandcompany.com/collections/syrups/products/rio-red-grapefruit-cordial',
  'Sugarcane Kola Syrup': 'https://www.liberandcompany.com/collections/syrups/products/sugarcane-kola-syrup',
  'Texas Grapefruit Shrub': 'https://www.liberandcompany.com/collections/syrups/products/texas-grapefruit-shrub',
  'Toasted Coconut Syrup': 'https://www.liberandcompany.com/collections/syrups/products/toasted-coconut-syrup',
  'Tropical Passionfruit Syrup':
    'https://www.liberandcompany.com/collections/syrups/products/tropical-passionfruit-syrup',
  'Pacific Strawberry Syrup': 'https://www.liberandcompany.com/collections/syrups/products/pacific-strawberry-syrup',
}

export function linkSyrupToPage(recipe: string) {
  if (!recipe) return recipe
  Object.keys(syrups).forEach(s => {
    recipe = recipe.replaceAll(s, `[${s}](${syrups[s]})`)
  })

  return recipe
}

export function replaceSyrupInRecipe(recipe: string) {
  if (!recipe) return recipe
  recipe.replace(/simply syrup/gi, `[Classic Gum Syrup](${syrups['Classic Gum Syrup']}`)
  recipe.replace(/grenadine/gi, `[Real Grenadine](${syrups['Real Grenadine']}`)
  return recipe
}

export function replaceSyrupsWithLiberSyrups(content: string) {
  content = content.replaceAll('simple syrup', 'Classic Gum Syrup')
  content = content.replaceAll('almond syrup', 'Almond Orgeat Syrup')
  content = content.replaceAll('grenadine', 'Real Grenadine')
  return content
}