import { Marked } from 'marked'
import { linkSyrupToPage, replaceSyrupsWithLiberSyrups } from './syrups'
const marked = new Marked()

let messages = []
let aiResponse = ''
let playedVideo = false

function handleResponseSplitting() {
  if (aiResponse.includes('[RECIPE]')) {
    const [content, rest] = aiResponse.split('[RECIPE]')
    addMessageToChatBox({ content, role: 'assistant' })
    aiResponse = rest
    playedVideo = true
    handleResponseSplitting() // Just incase it's a cached response

    playAssociatedVideo([messages[messages.length - 1], { content, role: 'assistant' }])
  } else if (aiResponse.includes('[END RECIPE]') || aiResponse.includes('[END_RECIPE]')) {
    const [content, rest] = aiResponse.split(/\[END(?: |_)?RECIPE\]/)
    addMessageToChatBox({ content, role: 'assistant' })
    aiResponse = rest
  }
}

function handleResponse(chunk: string, callback: (chunk: string) => void) {
  try {
    callback(chunk)
    aiResponse += chunk
    handleResponseSplitting()
  } catch (error) {
    console.error(error)
  }
}

async function handleSubmit(prompt: string) {
  let responseMessage = ''
  let input = document.querySelector<HTMLInputElement>('[data-chat="input-field"]')

  try {
    input.setAttribute('disabled', 'disabled')
    input.value = ''

    addMessageToChatBox({ content: prompt, role: 'user' })
    messages.push({ content: prompt, role: 'user' })

    const callAnui = (messages: ChatMessage[]) => {
      return fetch('/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages }),
      })
    }

    const response = await callAnui(messages) 
    if (!response.ok) throw new Error(response.statusText)
    if (!response.body) throw new Error('No response from the server')

    let done = false
    const reader = response.body.getReader()
    const decoder = new TextDecoder()

    while (!done) {
      const { value, done: doneReading } = await reader.read()
      done = doneReading

      handleResponse(decoder.decode(value), (chunk) => (responseMessage += chunk))
    }

    addMessageToChatBox({ content: aiResponse, role: 'assistant' }, true)
    messages.push({ content: responseMessage, role: 'assistant' })
    if (!playedVideo) playAssociatedVideo(messages)
    playedVideo = false
    aiResponse = ''
  } catch (error) {
    console.error(error)
  } finally {
    input.removeAttribute('disabled')
    if (!isMobileDevice()) input.focus()
  }
}

/**
 *
 * @param messages
 */
async function playAssociatedVideo(messages: ChatMessage[]) {
  const videosResp = await fetch('/videos/choose', {
    method: 'POST',
    headers: {
      'Content-Type': 'application',
    },
    body: JSON.stringify({ messages }),
  })

  const { videoIds } = (await videosResp.json()) as { videoIds: string[] }

  // choose one of the videos at random
  const video = videoIds[Math.floor(Math.random() * videoIds.length)]
  global.playVideo(video)
}

/**
 *
 * @param param0
 * @param lastMessage
 */
async function addMessageToChatBox({ content, role }: ChatMessage, lastMessage?: boolean) {
  const container = document.querySelector('[data-chat="chat-target"]')

  if (role === 'user') {
    const elem = document.createElement('div')
    elem.classList.add(`chat_message-response`, `is-input`)
    elem.dataset.chat = 'input-wrapper'
    elem.innerHTML = linkSyrupToPage(content)
    container.appendChild(elem)
  } else {
    const elem = document.createElement('div')
    elem.classList.add(`chat_message-response`, `is-response`)
    elem.dataset.chat = 'response-wrapper'
    if (lastMessage) elem.dataset.lastMessage = 'true'
    let updatedContent = content
    updatedContent = updatedContent.replaceAll(/\|introductions|conclusion\|/gi, '')
    updatedContent = updatedContent.replaceAll('Liber:', '')
    updatedContent = updatedContent.replaceAll('Salve!', '')
    updatedContent = replaceSyrupsWithLiberSyrups(updatedContent)
    updatedContent = linkSyrupToPage(updatedContent)

    elem.innerHTML = await marked.parse(updatedContent)

    elem.querySelectorAll('a').forEach((link) => {
      link.setAttribute('target', '_blank')
    })

    if (content.trim() === '') return
    container.appendChild(elem)
  }
}

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator?.userAgent || '')
}

export function chatWithLiber() {
  const input = document.querySelector<HTMLInputElement>('[data-chat="input-field"]')
  const submitButton = document.querySelector('[data-chat="submit-button"]')

  submitButton.addEventListener('click', (e) => {
    e.preventDefault()
    if (input.value.trim() === '') return
    handleSubmit(input.value)
  })

  input.addEventListener('keydown', (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault()
      // call method to send message
      handleSubmit(e.target.value)
    }
  })
}
